import React from "react"
import Layout from "../components/Layouts/Layout"
import SEO from "../components/SEO"
import { VerticalSpace } from "../components/VerticalSpace"

const description = "Reporting doesn't make your money business, and yet it's crucial to understand threats, trends and opportunities. Need reporting help?"

const ReportingServices: React.FunctionComponent = () => (
  <Layout title="Bespoke reporting services">
    <SEO title="Reporting Services"
      description="Reporting doesn't need to be like finding a needle in a haystack. Automate marketing and business reporting so you can focus on what matters. Get a quote." />
    <VerticalSpace height={50} />
    <p>
      #&amp;*$! - is this the word on your lips when your Excel document crashes for the third time while you try to join a series of convoluted spreadsheets in order to spit out a report that makes sense of your data?
      </p>
    <p>
      Are you at your wits’ end staring at the blue screen of death on your laptop and thinking of those happy, pre-Excel days, when you were a barista?
      </p>
    <p>
      You can stop smashing your keyboard in frustration; Creative Ilk offers bespoke data transformation and reporting services, so you can do what you do best: analyse data, not crunch it.
      Send a sample of the data you use and the output you want for an obligation-free quote and let us help you leave Excel in the dust.
      </p>
    <VerticalSpace height={50} />
    <form>
      <div className="form-group">
        <label htmlFor="contactFormNameInput">Your name</label>
        <input
          type="text"
          className="form-control"
          id="contactFormNameInput"
          placeholder="Nice to meet you!" />
      </div>

      <div className="form-group">
        <label htmlFor="contactFormEmailInput">Your email address</label>
        <input
          type="email"
          className="form-control"
          id="contactFormEmailInput"
          placeholder="name@example.com"
          required />
      </div>

      <div className="form-group">
        <label htmlFor="contactFormCompanyInput">Company name</label>
        <input
          type="text"
          className="form-control"
          id="contactFormCompanyInput"
          placeholder="Your company" />
      </div>

      <div className="form-group">
        <label htmlFor="inlineFormCustomSelectPref">How often do you require this reporting?</label>
        <select className="custom-select form-control" id="inlineFormCustomSelectPref" required>
          <option selected>Choose...</option>
          <option value="1">Daily</option>
          <option value="2">Weekly</option>
          <option value="3">Monthly</option>
          <option value="3">Quarterly</option>
          <option value="3">Annually</option>
          <option value="3">Other</option>
        </select>
      </div>


      <div className="form-group">
        <label htmlFor="exampleFormControlTextarea">What is the objective of your report?</label>
        <textarea className="form-control" id="exampleFormControlTextarea" rows={3} required></textarea>
      </div>

      <div className="form-group">
        <div className="row">
          <div className="col">
            <label htmlFor="ExampleInputReports">File sources: what files will you provide to us?</label>
            <input type="file" className="form-control-file" id="ExampleInputReports"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
          </div>

          <div className="col">
            <label htmlFor="ExampleOutputReport">Example output: what format do you expect to see back?</label>
            <input type="file" className="form-control-file text-center" id="ExampleOutputReport"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
          </div>
        </div>
      </div>


      <VerticalSpace height={40} />

      <button type="submit" className="btn btn-primary btn-lg">Submit</button>
    </form>

    <VerticalSpace height={75} />
  </Layout >
)


export default ReportingServices


